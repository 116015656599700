import React from 'react';
import '..//App.css';
import TitleBar from '../Components/TitleBar';
import Footer from '../Components/Footer';
import Carousel from '../Components/Carousel';

const BlogSection = () => {


    return (
        <div className='MainContent' id='BlogSection'>
            <div className='BlogSection'>
                <h2>
                    Blog
                </h2>
                <br/>
                <div className='MidBar'/>
                <br/>
                    {/* <Carousel/> */}
            </div>

        </div>
    );

}
export default BlogSection;