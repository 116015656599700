import React from "react";

const Reports = () => {


    return (
        <div className="MainContent" id="Reports">
            <div className='TitleSection'>
                <h2>
                    Reports
                </h2><br/>
                <div className='MidBar' />
            </div>
        </div>
    );
}
export default Reports;