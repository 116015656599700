import React, { Component } from 'react';
import { BrowserRouter} from "react-router-dom";
import Pages from './Pages/Pages';
// import "./App.css";
import Navbar from './Components/Navbar';
import Home from './Pages/HomePage';
import Footer from './Components/Footer';
import BlogSection from './Pages/BlogSection';
import Projects from './JS/Projects';
import About from './Pages/About';
import ProjectSection from './Pages/ProjectSection';
import Reports from './Pages/Reports';
import Contact from './Pages/Contact';



class App extends Component {
  
  render() {
    return(
      <>
      {/* <BrowserRouter>
        <Pages />
      </BrowserRouter> */}
      <div>
        <Navbar/>
        <Home/>
        {/* <BlogSection/> */}
        <ProjectSection/>
        <Reports/>
        <About/>
        <Contact/>
        <Footer/>
      </div>
      </>
  );}
}

export default App;


