import React, { useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import { FaBars, FaTimes } from 'react-icons/fa';
import "../CSS/navbar.css";

const Navbar = () => {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);

    const closeMenu = () => setClick(false);

    return (
        <div className="header">
            <nav className="navbar">
                <div className="logo">
                    <h2 className="TitleHeader">Indatastar</h2>
                </div>
                <div className="hamburger" onClick={handleClick}>
                    {click ? (<FaTimes size={30} style={{ color: '#ffffff' }} />)
                        : (<FaBars size={30} style={{ color: '#ffffff' }} />)}
                </div>
                <ul className={click ? "nav-menu active" : "nav-menu"}>
                    <li className="nav-item">
                        <ScrollLink
                            to="home"
                            smooth={true}
                            duration={500}
                            offset={-90}
                            spy={true}
                            activeClass="active"
                            onClick={closeMenu}
                        >
                            Home
                        </ScrollLink>
                    </li>
                    {/* <li className="nav-item">
                        <ScrollLink
                            to="BlogSection"
                            smooth={true}
                            duration={500}
                            spy={true}
                            offset={-90}
                            activeClass="active"
                            onClick={closeMenu}
                        >
                            Blog
                        </ScrollLink>
                    </li> */}
                    <li className="nav-item">
                        <ScrollLink
                            to="ProjectSection"
                            smooth={true}
                            duration={500}
                            offset={-90}
                            spy={true}
                            activeClass="active"
                            onClick={closeMenu}
                        >
                            Projects
                        </ScrollLink>
                    </li>
                    <li className="nav-item">
                        <ScrollLink
                            to="Reports"
                            smooth={true}
                            offset={-90}
                            duration={500}
                            spy={true}
                            activeClass="active"
                            onClick={closeMenu}
                        >
                            Reports
                        </ScrollLink>
                    </li>
                    <li className="nav-item">
                        <ScrollLink
                            to="About"
                            smooth={true}
                            offset={-90}
                            duration={500}
                            spy={true}
                            activeClass="active"
                            onClick={closeMenu}
                        >
                            About
                        </ScrollLink>
                    </li>
                    <li className="nav-item">
                        <ScrollLink
                            to="Contacts"
                            smooth={true}
                            offset={-90}
                            duration={500}
                            spy={true}
                            activeClass="active"
                            onClick={closeMenu}
                        >
                            Contact Us
                        </ScrollLink>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Navbar;
