import React from "react";
import Slider from "react-slick";
import "..//";

const Carousel = () =>{
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="carousel-container">
            <Slider {...settings}>
            <div className="carousel-item">
                    <h3>Slide 1</h3>
                    <p>This is the first slide.</p>
                </div>
                <div className="carousel-item">
                    <h3>Slide 2</h3>
                    <p>This is the second slide.</p>
                </div>
                <div className="carousel-item">
                    <h3>Slide 3</h3>
                    <p>This is the third slide.</p>
                </div>
            </Slider>
        </div>
    );
}

export default Carousel;