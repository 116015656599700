import React, { useState } from 'react';
import '..//App.css';
import TitleBar from '../Components/TitleBar';
import Projects from '../JS/Projects';





const ProjectSection = () => {


    const [ShowInProgress,SetShowInProgress] = useState(false)
    const [ShowCurrent,SetShowCurrent] = useState(false)
    const [ShowCompleted,SetShowCompleted] = useState(false)
    const [InprogressArray,SetInProgressArray] = useState([]);
    const [CurrentArray,SetCurrentArray] = useState([]);
    const [CompletedArray,SetCompletedArray] = useState([]);



    const ShowAllInProgress = () =>{
        if(InprogressArray.length > 0)
        {
            SetShowInProgress(!ShowInProgress);
        }
    }

    const ShowAllCurrent = () =>{
        if(CurrentArray.length > 0)
        {
            SetShowCurrent(!ShowCurrent);
        }
    }

    const ShowAllCompleted = () =>{
        if(CompletedArray.length > 0)
        {
            SetShowCompleted(!ShowCompleted);
        }
    }




    return (
            <div className='MainContent' id='ProjectSection'>
                <div className='TitleSection'>
                    <h2>
                        Projects
                    </h2><br/>
                    <div className='MidBar' />
                </div>
            </div>
    );

}
export default ProjectSection;