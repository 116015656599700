import {React,useState,useEffect} from 'react';
import styled from "styled-components";
import '..//App.css';
import TitleBar from '../Components/TitleBar';


function Contact() {

    const [OnResponse,SetResponse] = useState([true]);

    const handleSubmit = (e) =>{
        e.preventDefault();
        console.log('Submit');
        if(SetResponse){
            SetResponse(false);

        }
        else
        {
            SetResponse(true);
        }
    }

    useEffect(() =>{

    });
  
    return (
        <div className='MainContent' id='Contacts'>
            <div className='ContactSection'>
                <h2>
                    Contact Us
                </h2><br/>
                <div className='MidBar' />
                <br/>
                {
                    OnResponse ?(
                        <form  className='FormBox' onSubmit={handleSubmit}>
                        <div className='FormGroup'>
                            {/* <label htmlFor='firstName'>First Name:</label> */}
                            <input id='firstName' type='text' placeholder='First Name'/>
                        </div>
                        <div className='FormGroup'>
                            {/* <label htmlFor='lastName'>Last Name:</label> */}
                            <input id='lastName' type='text' placeholder='Last Name'/>
                        </div>
                        <div className='FormGroup fullWidth'>
                            {/* <label htmlFor='email'>Email:</label> */}
                            <input id='email' type='email'  placeholder='Email'/>
                        </div>
                        <div className='FormGroup fullWidth'>
                            {/* <label htmlFor='message'>Message:</label> */}
                            <textarea id='message' placeholder='Message'></textarea>
                        </div>
                            <div className='FormGroup fullWidth'>
                            <input className='custom-button' type='submit' value='Send' />
                        </div>
                    </form>
                    ):(
                        <div className='resultbox'>
                            <h3>Thank you for reaching out.</h3> 
                            <h3>We will be in with you touch as soon as possible</h3>
                            <br/>
                            <p>
                                In the meantime check out what we're working on so far
                            </p>
                        </div> 
                    )
                }

            </div>
        </div>
    );
}
export default Contact;

//