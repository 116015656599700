import React from 'react';
import BlogSection from './BlogSection';
// import '..//App.css';
import TitleBar from '../Components/TitleBar';
import Footer from '../Components/Footer';
import styled, { keyframes } from "styled-components";



 function Home() {
    return(
        <div className='MainContent' id='home'>
                <div className='TitleSection'>
                    <h1>
                        Indatastar
                    </h1><br/>
                    <div className='MidBar' />
                    <h4>
                        Tech Solutions
                    </h4>
                </div>
        </div>
    )
};
 export default Home;