import React from 'react';
import styled from "styled-components";
import '..//App.css';
import TitleBar from '../Components/TitleBar';

function About() {

  
    return (
            <div id='About' className='MainContent'>
            <div className='TitleSection'>
                <h2>
                    About
                </h2><br/>
                <div className='MidBar' />
            </div>
            </div>
    );
}
export default About;